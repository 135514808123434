$(document).ready(() => {
	$('[data-toggle="collapse"]').each(function(){
		$(this).click(function(){
            let active = ($(this).hasClass('active')) ? true : false; 
            let icon = $(this).find('img'); /* for Laudes, the html is '.collapsible-toggle img' */

            // toggle + and - icon
            $(this).toggleClass('active');
            if (!active) {
                icon.attr('src', icon.attr("data-src-active"));
            } else {
                icon.attr('src', icon.attr("data-src-inactive"));
            }

            // toggle aria
            if ( $(this).find('button').attr('aria-expanded') == 'true' ) {
                $(this).find('button').attr('aria-expanded', 'false');
                $('.toggle-icon').attr('alt', 'Toon antwoord');
            } else {
                $(this).find('button').attr('aria-expanded', 'true');
                $('.toggle-icon').attr('alt', 'Verberg antwoord');
            }

            // toggle collapsible-body
            $($(this).data('target')).hide('slow').stop(true, false, true).toggle('fast');
		});

        // toggle collapsible on enter press
        $(this).on('keypress',function(e) {
            if(e.which == 13 && $(this).is(":focus") ) {
                $(this).click();
            }
        });
	});
});