export default class FormValidator{
	processForms() {
		// form validation

		// 1. Disable native validation UI with `noValidate`
		// 2. On submit, run evaluation and prevent if necessary
		const $forms = $('form');

		$forms.each((i, el) => {
			let form = el;
			let $form = $(form);

			form.noValidate = true;
			form.onsubmit = evt => {
				if (!form.checkValidity()) {
					evt.preventDefault();
					$form.find('.invalid:first').focus();
			  	}
			}

			// Iterate over fields in form
			$form.find('input, textarea, select').each((i, field) => {
				this.addFieldValidation(field);
			});
		});
	}

	addFieldValidation(field) {
        let $field = $(field);
        let $error;

        if ($field.prev().prop('tagName') == 'UL') {
            //an error exists
            let errorStr = '';
            $field.prev().find('li').each((indx,errorMsg) => {
                errorStr += errorMsg.innerHTML;
                errorMsg.remove();
            });
            $error = $('<div class="error invisible">'+errorStr+'</div>');
            $field.after($error);
            $error.removeClass('invisible');
            $field.focus();
        } else {
            // Add error container
            $error = $('<div class="error invisible"></div>');
            $field.after($error);
        }



        // Show message on `invalid` event
        $field.on('invalid', () => {
            $field.addClass('invalid');
            $error.text(field.validationMessage);
            $error.removeClass('invisible');

            // Reset invalid state & error message on `input` event, trigger validation check
            const inputHandler = () => {
                $field.off('input');
                $error.text('');
                $error.addClass('invisible');
                $field.removeClass('invalid');
                field.checkValidity();
            }
            if ($field.hasClass('date-field')) {
                $field.on('change', inputHandler);
            }
            $field.on('input', inputHandler);
        });
        field.addEventListener("blur", function() {
            field.checkValidity();
        });
    }

}
