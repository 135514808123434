import $ from "jquery";
import TinyDatePicker from "tiny-date-picker";
import FormValidator from '../lib/FormValidator.js';

export default class InnovationFundForm {

    constructor() {

        $(document).ready(() => {
            this.initForm();

            // simple max length counter for textarea : project description
            const label = $('#innovatiefonds_form_project_description').parent().find('label');
            const labelText = label.text();

            $('#innovatiefonds_form_project_description').on('change keyup paste', function(e) {
                let counter = $(this).attr('maxlength') - $(this).val().length ;

                label.text(labelText + " (nog " + counter + " tekens over)");
            });
        });


    }

    initForm() {
        $('.add-another-collection-widget').click(function (e) {
            var list = $($(this).attr('data-list-selector'));
            // Try to find the counter of the list or use the length of the list
            var counter = list.data('widget-counter') || list.children().length;

            // grab the prototype template
            var newWidget = list.attr('data-prototype');
            // replace the "__name__" used in the id and name of the prototype
            // with a number that's unique to your emails
            // end name attribute looks like name="contact[emails][2]"
            newWidget = newWidget.replace(/__name__/g, counter);
            // Increase the counter
            counter++;
            // And store it, the length cannot be used if deleting widgets is allowed
            list.data('widget-counter', counter);

            //add delete button to the widget when it is set
            var newDeleteButton = list.data('widget-delete') || '';
            newWidget += newDeleteButton;

            // create a new list element and add it to the list
            var newElem = $(list.attr('data-widget-tags')).html(newWidget);
            newElem.appendTo(list);

            newElem.find('.delete-collection-widget').click(function (e) {
                deleteCollectionWidget(e);
            });

            TinyDatePicker('#innovatiefonds_form_activities_'+(counter-1)+'_project_activity_start', tinyDatePickerVals );
            TinyDatePicker('#innovatiefonds_form_activities_'+(counter-1)+'_project_activity_end', tinyDatePickerVals );

            $('#innovatiefonds_form_activities_'+(counter-1)+'_project_activity_start, #innovatiefonds_form_activities_'+(counter-1)+'_project_activity_end').inputFilter(function(value) {
                return /^[0-9.-]*$/.test(value); // Allow digits and dashes only, using a RegExp
            });

            let formValidator  = new FormValidator();
            newElem.find('input, textarea, select').each((i, field) => {
                formValidator.addFieldValidation(field);
            });
            // formValidator.addFieldValidation('#innovatiefonds_form_activities_'+(counter-1)+'_activity_description');
            // formValidator.addFieldValidation('#innovatiefonds_form_activities_'+(counter-1)+'_project_activity_start');
            // formValidator.addFieldValidation('#innovatiefonds_form_activities_'+(counter-1)+'_project_activity_end');

        });

        $('form[name="innovatiefonds_form"]').find('.invalid:first').focus();

        $('.delete-collection-widget').click(function (e) {
            deleteCollectionWidget(e);
        });

        var deleteCollectionWidget = function (e) {
            e.preventDefault();
            let confirmation = confirm('Weet u zeker dat u deze activiteit wilt verwijderen?');
            if (confirmation) {
                if ($(e.target).data('remove')) {
                    //console.log(eval($(e.target).data('remove')));
                    eval($(e.target).data('remove')).remove();
                } else {
                    $(e.target).parent().remove();
                }

            }
        }

        const tinyDatePickerVals = { lang: {
                days: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
                months: [
                    'Januari',
                    'Februari',
                    'Maart',
                    'April',
                    'Mei',
                    'Juni',
                    'Juli',
                    'Augustus',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                today: 'Vandaag',
                clear: 'Wissen',
                close: 'Sluiten',
            },

            // format {Date} -> string is a function which takes a date and returns a string. It can be used to customize
            // the way a date will look in the input after the user has selected it, and is particularly
            // useful if you're targeting a non-US customer.
            format(d) {
                const nlStyleDate = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear();

                return nlStyleDate;
            },

            // parse {string|Date} -> Date is the inverse of format. If you specify one, you probably should specify the other
            // the default parse function handles whatever the new Date constructor handles. Note that
            // parse may be passed either a string or a date.
            parse(str) {
                var date = new Date(str);
                return isNaN(date) ? new Date() : date;
            },

            // mode {'dp-modal'|'dp-below'|'dp-permanent'} specifies the way the date picker should display:
            // 'dp-modal' displays the picker as a modal
            // 'dp-below' displays the date picker as a dropdown
            // 'dp-permanent' displays the date picker as a permanent (always showing) calendar
            mode: 'dp-below'};

        TinyDatePicker('#innovatiefonds_form_project_start', tinyDatePickerVals );
        TinyDatePicker('#innovatiefonds_form_project_end', tinyDatePickerVals );
        // TinyDatePicker('#innovatiefonds_form_project_activity_start', tinyDatePickerVals );
        // TinyDatePicker('#innovatiefonds_form_project_activity_end', tinyDatePickerVals );

        // Innavatiefonds form
        $('.co-op-option').change(function(e) {
            $(this).parents('.multi-col').find('.co-op-text input').toggle();
        });

        // Restricts input for the set of matched elements to the given inputFilter function.
        (function($) {
            $.fn.inputFilter = function(inputFilter) {
            return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                    this.value = "";
                }
            });
            };
        }(jQuery));

        $("#innovatiefonds_form_project_start, #innovatiefonds_form_project_end").inputFilter(function(value) {
            return /^[0-9.-]*$/.test(value); // Allow digits and dashes only, using a RegExp
        });
    }
}