import QueryString from '../lib/QueryString.js';
import InfiniteScroll from '../lib/InfiniteScroll.js';
import $ from "jquery";
import select2 from 'select2';
import Utils from "../lib/Utils";

export default class ProjectIndex {

    constructor() {
        // Initialize QueryString obj default behaviour
        const qs = new QueryString();

        // ---------------------[ Infinite Scroll Module ]--------------------- //
        /**
         * Initiate and set up the configuration of infinite scroll.
         */
        let segParam = qs.get('segment');
        let segment = segParam && !isNaN(segParam) ? parseInt(segParam) : 0;
        const config = {
            'segment': segment,
            'container': '#projects-content',
            'autoFill': true,
            'ajaxRoute': '/projects/data',
            'onSuccess': this.appendProjects,
            'updateParam': qs.updateParam
        }
        const infiniteScroll = new InfiniteScroll(config);

        $(document).ready(() => {
            this.initMap();
        });

        // --------------------- [ Filter & Search ] --------------------- //
        const $filterForm = $('form#filter-bar');
        $filterForm.submit(e => e.preventDefault());
        $filterForm.change((e) => {
            let params = $(e.currentTarget).serialize();
            qs.set(decodeURI(params));

            $('#projects-content').empty();
            infiniteScroll.reset();
            infiniteScroll.fetchData();

            // Update the total count of items found based on the current filters
            this.loadMarkers();
        });

        // set up real-time search with 0.3s delay
        let typingTimeout;
        $('form#filter-bar input#search').on('input', (e) => {
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(() => $filterForm.trigger("change"), 300);
        });

        //restore filter state
        qs.getValues('soilType[]').forEach(e => e && $(`form#filter-bar #soilType option[value="${decodeURI(e)}"]`).prop('selected', true));
        qs.getValues('projectType[]').forEach(e => e && $(`form#filter-bar #projectType option[value="${decodeURI(e)}"]`).prop('selected', true));
        qs.getValues('subject[]').forEach(e => e && $(`form#filter-bar #subject option[value="${decodeURI(e)}"]`).prop('selected', true));
        $(`form#filter-bar input#search`).val(decodeURI(qs.get('search')));

        // select 2
        $('.js-select2').select2({
            width: 'resolve',
            minimumResultsForSearch: Infinity,
        });
    }

    initMap() {
        this.map = L.map('map', {minZoom: 7, maxZoom: 13, worldCopyJump: true});
        this.markerLayer;
        this.markers=[];

        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions"  target="_blank">CARTO</a>',
            maxZoom: 14
        }).addTo(this.map);

        this.defaultIcon = L.icon({
            iconUrl: '/dist/images/marker-icon.svg',
            shadowUrl: '',
            iconSize:     [20, 32], // size of the icon
            shadowSize:   [0, 0], // size of the shadow
            //iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
            shadowAnchor: [0, 0],  // the same for the shadow
            //popupAnchor:  [1, -27] // point from which the popup should open relative to the iconAnchor
        });

        this.markers = L.markerClusterGroup({showCoverageOnHover: false, chunkedLoading: true, maxClusterRadius: 50});
        this.markerList = [];

        this.map.setView([52.21158, 5.600498], 8);
        this.loadMarkers();

    }

    async loadMarkers(e) {

        var bounds = this.map.getBounds();
        var defaultIcon = this.defaultIcon;
        var markerList = [];
        var markers = this.markers;
        var map = this.map;
        var markerLayer = this.markerLayer;

        $.ajax({
            url: '/projects/markers',
            method: 'GET',
            data:  $('form#filter-bar').serializeArray(),
            contentType: "application/json; charset=utf-8",
            success: function (result, status, jqxhr) {
                markers.clearLayers();
                map.removeLayer(markers);

                $.each(result, function (i, location) {
                    var marker;
                    switch (location.type) {
                        case 'marker':
                            marker = L.marker(location.marker, {icon: defaultIcon, url: location.url, alt: 'Project: ' + location.name});
                            break;
                        case 'polygon':
                            marker = L.polygon(location.polygon,  {url: location.url, color: '#6E8900', opacity: 0.6, weight: 2});
                            break;
                        default:
                            return;
                    }

                    // When an project name is too long a smaller fontsize will be rendered.
                    const projectNameLength = 100;
                    location.name.length >= projectNameLength
                        ? marker.bindPopup(location.name, {'className': 'small-fontsize'})
                        : marker.bindPopup(location.name);

                    marker.on('mouseover', function () {
                        marker.openPopup();

                    });
                    marker.on('mouseout', function () {
                        marker.closePopup();
                    });

                    marker.on('click', function (e) {
                        e.originalEvent.preventDefault();
                        window.location.href = e.target.options.url;
                    });
                    markerList.push(marker);

                });

                markers.addLayers(markerList);
                markerLayer = map.addLayer(markers);

                //map.fitBounds(markers.getBounds());
                map.setView([52.21158, 5.600498], 8);
            }
        });
    }




                /**
     * Callback for Ajax infinity scroll request
     */
    appendProjects(res) {
        res.forEach((project) => {
            $('#projects-content').append(`
               <div class="col-xs-6 col-md-12 col-lg-6">
                   <div class="card">
                   <a class="card-link" href="/project/${project.key}/${project.id}">
                        <div class="card-body direction-column">
                            <p class="subtext">${project.location}</p>
                            <h3 class="card-title">${project.name}</h3>
                        </div>
                        <div class="card-image-wrap">
                            ${project.imageHtml}
                        </div>
                        </a>
                    </div>
               </div>
            `)
        });
    }
}