
import $ from "jquery";

export default class PartnerForm {
    constructor() {

        $(document).ready(() => {
            $('#partner_form input#partner_form_address').val('v' + (Math.random() * 42)); //Fill in honeypot trap field

            // checkboxes generated by symphony cannot be made required, so this is a workaround
            $('.wrapper-of-required-input input[type=checkbox]').prop('required', true);

        });

    }


}