$(document).keydown(function(e) {
    if(e.which == 27){
        const $modals = $('[data-modal]');

        $modals.addClass('hide');
		$modals.parent().removeClass('active');
		// Remove content so next show dialog won't show old data
		$modals.find('#modal-body').empty();
    }
});

class Modals {
	constructor(){
		this.cbs = [];
	}

	registerCb(func){
		this.cbs.push(func);
	}

	show(targetRef) {
		let $target = null;

		switch(typeof targetRef){
			case 'string':
				$target = $('[data-modal="'+targetRef+'"]');
				break;
			case 'object':
				$target = targetRef;
				break;
			default:
				$target = null;
		}
		if (!$target) return;

		const $modals = $('[data-modal]');

		if(typeof $modals !== 'undefined' && $modals.length) {
			$modals.not($target).addClass('hide');
			if ($target.hasClass('hide')) {
				// Show dialog
				$target.removeClass('hide');
			} else {
				// Hide dialog 
				$target.addClass('hide');
			}
			if ($modals.filter('.hide').length < $modals.length) {
				$modals.parent().addClass('active');
			} else {
				$modals.parent().removeClass('active');
			}
		}
	};

	initModals() {
		const $modals = $('[data-modal]');

		$modals.each((index, el) => {

			let $modal = $(el);
			let noClose = typeof($modal.data('noclose')) !== 'undefined';
			this.initModal($modal, noClose);
		});
	};

	initModal($target, noClose) {
		let modalId = $target.data('modal');
		let $triggers = $('[data-trigger-modal="'+modalId+'"]');
		let $close = $target.find('.close');

		if (noClose) {
			$close.remove();
		} else {
			$triggers = $triggers.add($close);
		}

		$triggers.off();
		$triggers.on('click', (e) => {
			e.preventDefault();
			this.show($target);
			return false;
		});

		this.cbs.forEach( el => {
			if(typeof el === "function"){
				el();
			}
		})

		return {
			show: () => {
				this.show($target);
			}
		};
	}
}

export default (new Modals);
