/**
 * @version 2.1
 * @author Mahmoud Al-Refaai <Schuttelaar & Partners>
 * 
 * v2.1 Changes ----------
 *  - Add autoScroll to config which let the view scroll down to last segment on initiate
 *  - Add reset() function that reset value (normally used on filter change)
 *  - Support list-param, ie key with multiple values and end with "[]" or its encoded value "%5B%5D"
 *    when converting query string to object as dataParams in fetch call
 *  - Alias: fetch() => fetchData()
 * 
 * v2.0 Changes ----------
 *  - Add getAjaxData() to config, which returns the data need to be used in AJAX request,
 *    instead of hard-coding the data in fetch function. The default value is current window query string.
 *  - getAjaxData() config should now return an object with { key: value } list,
 *    However, a normal query string can be used, and a conversion to object will happen on fetch.  
 *  - Check 'NoMoreContent' in response header. In case it is set to true, the infinityScroll will be locked accordingly.
 *    The fallback of not having 'NoMoreContent' in response header is the same as previous 
 *    version behaviour, ie. lock infinityScroll after first request with no results.
 *  - Change updateParam() in config to updateSegmentParam(), which takes only the segment number to update queryString
 *    This changes enable specifying the parameter name (page, segment.. etc), as shown in this example: 
 *    config = { updateSegmentParam: seg => queryString.updateParam('PARAM_NAME', seg) }.
 *  - Add fetchOnInitiate to config, which allow for initial fetch with "initial" param set to retrieve 
 *    all data till the specified segment on page load.
 * 
 * v1.1 Changes ----------
 *  - [Fix] Bind all class' function to "this" in constructor
 *  - Add ajaxDataType to config param in constructor
 *  - remove the use of setter "set attributeName()" syntax because of binding issue
 *    It is now a normal function, ie. setLockInfiniteScroll(), setSegment()
 *  - Use "segment" param instead of "page" in url query string
 */

import $ from "jquery";

export default class InfiniteScroll {

    /**
     * Constructor of InfiniteScroll object
     * @param {int} config.segment              the number of segment to start on
     * @param {String} config.container         the query-string of the container (eg: "#containerId" or ".containerClass")
     * @param {Bool} config.lockInfiniteScroll  if set to true, scrolling down won't trigger the fetch function
     * @param {Bool} config.autoFill            boolean weather to keep fetching data until the page is filled (ie. scrollbar appear)
     * @param {Bool} config.fetchOnInitiate     boolean to fetch all data till the specified segment on infinite scroll initiate.      
     * @param {String} config.ajaxRoute         the url-route to be used in AJAX
     * @param {String} config.ajaxDataType      the data-type of the response of the AJAX request
     * @param {function} config.getAjaxData     function return the data (query string) used in AJAX request
     * @param {function} config.onSuccess       callback function(res) when AJAX request succeed
     * @param {function} config.onError         callback function(err) when AJAX request failed
     * @param {function} config.updateSegmentParam callback function(segment) to update the segment param in query string
     */
    constructor(config) {
        this.config = {
            segment: 1,
            container: '',
            lockInfiniteScroll: false,
            autoFill: false,
            autoScroll: false,
            fetchOnInitiate: false,
            ajaxRoute: '',
            ajaxDataType: 'json',
            getAjaxData: () => window.location.search.substr(1),
            onSuccess: () => {},
            onError: () => {},
            updateSegmentParam: () => {}
        };

        this.editConfig(config);
        this.addScrollLsn();
        this.$resultsContainer = $(this.config.container);

        // fetch initial data;
        if (this.config.fetchOnInitiate || this.config.autoFill)
            this.fetchData().then((moreContent) => {

                //scroll down to last segment after initial fetch is done
                if (this.config.autoScroll && this.config.segment > 1)
                    document.querySelector(this.config.container + " a:nth-last-child(3)")
                    .scrollIntoView({ behavior: "smooth" });


                //if autoFill is enabled, keep fetching next segments to fill the page
                moreContent && this.config.autoFill && this.autoFill();
            }).catch(() => {}); //in case of error (rejected promise), ignore it!

        //if fetch on initial isn't set, scroll down directly
        else if (this.config.autoScroll && this.config.segment > 1)
            document.querySelector(this.config.container + " a:nth-last-child(3)")
            .scrollIntoView({ behavior: "smooth" });

        // Bind all class' functions to "this"
        const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
        methods
            .filter(method => (method !== 'constructor'))
            .forEach((method) => { this[method] = this[method].bind(this); });
    }

    editConfig(config) {
        for (let key in config)
            this.config[key] = config[key];
    }

    /**
     * Add the scroll event listener to this container
     */
    addScrollLsn() {
        $(window).scroll(() => {
            if (!this.config.lockInfiniteScroll &&
                ($(document).scrollTop() + $(window).height()) >= (this.$resultsContainer.offset().top + this.$resultsContainer.height() - 100))
                this.fetchData();
        });
    }

    /**
     * Remover the scroll listener from this container
     */
    removeScrollLsn() {
        this.$resultsContainer.unbind('scroll');
    }

    /**
     * Repeat fetching data until the page is filled (ie. scrollbar is shown).
     */
    autoFill() {
        if ($(window).height() >= $(document).height()) {
            this.fetchData()
                .then((moreContent) => {
                    //in case there is still more content to fetch, check again if the page is not filled.
                    moreContent && this.autoFill();
                })
                .catch(() => {}); //in case of error (rejected promise), ignore it!
        }
    }

    /**
     * preform ajax request through the given route in constructor
     */
    async fetchData() {
        if (this.config.lockInfiniteScroll) return;
        this.config.lockInfiniteScroll = true;

        let dataParams = this.config.getAjaxData() || {};

        //In case getAjaxData return a query string, convert it to object
        if (typeof dataParams === "string") {
            let temp = {};
            dataParams.split('&').forEach(e => {
                let param = e.split('=');
                param[0] = decodeURI(param[0]);

                if (param[0].slice(-2) === "[]") {
                    if (!temp[param[0]]) temp[param[0]] = [];
                    temp[param[0]].push(decodeURI(param[1]));
                } else {
                    temp[param[0]] = decodeURI(param[1]);
                }
            });
            dataParams = temp;
        }

        //Add initial param if this an initial fetch (on page load)
        if (this.config.fetchOnInitiate) {
            this.config.fetchOnInitiate = !this.config.fetchOnInitiate;
            dataParams.initial = 1;

            // Since this is an initial fetch, we don't need segment to increase,
            // so decrease here to cancel out with the increase in response callback.
            this.config.segment--;
        }

        // fetch next segment
        dataParams.segment = this.config.segment + 1;

        return new Promise((resolve, reject) => {
            $.ajax({
                url: this.config.ajaxRoute,
                method: 'GET',
                data: dataParams,
                dataType: this.config.ajaxDataType,
                success: (res, status, XHR) => {
                    if ($(res).length) {
                        const noMoreContent = XHR.getResponseHeader('NoMoreContent');
                        this.config.segment++;
                        this.config.updateSegmentParam(this.config.segment);

                        // in case the field 'noMoreContent' doesn't exist, the fallback is falsy value
                        this.config.lockInfiniteScroll = noMoreContent;
                        this.config.onSuccess(res);
                        resolve(!noMoreContent);
                    } else {
                        this.config.lockInfiniteScroll = true;
                        resolve(false);
                    }
                },
                error: this.config.onError
            });
        });
    }
    async fetch() { return this.fetchData(); }

    /**
     * @param {boolean} lock set to false to unlock the infinite scroll.
     */
    setLockInfiniteScroll(lock = true) {
        this.config.lockInfiniteScroll = lock;
    }

    /**
     * @param {int} segmentNo the new segment number to be set on.
     */
    setSegment(segmentNo = 1) {
        this.config.segment = segmentNo;
    }

    /**
     * Reset the segment to 0, and unlock the infinite scroll.
     */
    reset() {
        this.config.fetchOnInitiate = true;
        this.config.lockInfiniteScroll = false;
        this.config.segment = 1;
    }
}