import '../scss/index.scss';

import jQuery from 'jquery';
import simpleshare from './lib/simpleshare.jquery';
import inView from 'in-view';
import socialshares from 'socialshares';

// useful polyfills
import smoothscroll from 'smoothscroll-polyfill';

// tooltips/popups
import tippy, { inlinePositioning } from 'tippy.js';

import './lib/ScreenDetector'; // initializes screensize etc.
import './lib/collapsible';
import FormValidator from './lib/FormValidator.js';
import Modals from './lib/Modals.js';
import QueryString from './lib/QueryString.js';
import InfiniteScroll from './lib/InfiniteScroll.js';
import Utils from "./lib/Utils";

import leaflet from 'leaflet';
import leafletMarkerCluster from 'leaflet.markercluster';
import leafletProvider from 'leaflet-providers';
import leafletDraw from 'leaflet-draw';

import TinyDatePicker from 'tiny-date-picker';

// import js for specific pages
import PageHome from './pages/Home.js';
import PageUpdates from './pages/Updates.js';
import PartnerIndex from "./pages/Partners";
import PartnerForm from "./pages/PartnerForm";
import Search from "./pages/Search";
import ProjectIndex from "./pages/ProjectIndex";
import ProjectDetail from "./pages/ProjectDetail";
import ProjectForm from "./pages/ProjectForm";
import ThemesOverview from "./pages/Themes";
import InnovationFundForm from "./pages/InnovationFundForm";

const page = $('body').data('page-key');
switch (page) {
    case 'home':
        new PageHome();
        break;
    case 'partners':
        new PartnerIndex();
        break;
    case 'partner-form':
        new PartnerForm();
        break;
    case 'updates':
        new PageUpdates();
        break;
    case 'search':
        new Search();
        break;
    case 'projects':
        new ProjectIndex();
        break;
    case 'project-detail':
        new ProjectDetail();
        break;
    case 'project-form':
        new ProjectForm();
        break;
    case 'themes-overview':
        new ThemesOverview();
        break;
    case 'innovatiefonds-form':
        new InnovationFundForm();
        break;
}

function setOnScroll(cb) {
	// Reference: http://www.html5rocks.com/en/tutorials/speed/animations/

	let last_known_scroll_position = 0;
	let ticking = false;

	window.addEventListener('scroll', e => {
		last_known_scroll_position = window.scrollY;

		if (!ticking) {
			window.requestAnimationFrame(() => {
				cb(last_known_scroll_position);
				ticking = false;
			});
			ticking = true;
		}
	});
}

$(document).ready(() => {
    const navMenuToggle = document.querySelector('.nav-menu-toggle');
    const topbar = document.querySelector('#topbar');

	window.scroll(0,-1); // scroll one pixel up on page load to trigger menu styling if user has scrolled before page refresh

	let scrollExceedsHeight = false;
	setOnScroll((pos) => {
		if (pos > topbar.getBoundingClientRect().height) {
			if (scrollExceedsHeight === false) {
				scrollExceedsHeight = true;
				topbar.classList.add('alternate');
			}
		} else {
			if (scrollExceedsHeight === true &&  navMenuToggle.checked == false ) {
				scrollExceedsHeight = false;
				topbar.classList.remove('alternate');
			}
		}
	})

	// simpleshare
	$('.simpleshare').simpleShare({ facebook: true, twitter: true, linkedin: true, whatsapp: false });

	smoothscroll.polyfill();

	//Modals.initModals(); initializes modals from existing HTML
	new FormValidator().processForms();

    navMenuToggle.addEventListener('click', event => {
        if (scrollExceedsHeight === false) {
            if ( navMenuToggle.checked == true ) {
                topbar.classList.add('alternate');
            } else {
                topbar.classList.remove('alternate');
            }
        }
    });

    // when Enter is pressed while hamburger is focused
    $(document).on('keypress',function(e) {
        if(e.which == 13 && $(".hamburger").is(":focus") ) {
            navMenuToggle.click();
            toggleAria($(".hamburger"));
            trapFocus( $('#topbar'), $('.hamburger'), '.socialmedia-links' );
        }
    });

    $(".hamburger").click(function(e){
        toggleAria($(".hamburger"));
        trapFocus( $('#topbar'), $('.hamburger'), '.socialmedia-links' );
    });

    function toggleAria(item) {
        if ( item.attr('aria-expanded') == 'true' ) {
            item.attr('aria-expanded', 'false');
        } else {
            item.attr('aria-expanded', 'true');
        }
    }

    // Set all youtube links to disablekb=1
    $('iframe').each(function(e){
        let newSrc = $(this).attr('src');
        
        if (newSrc.indexOf("?") >= 0) {
            newSrc = newSrc + "&disablekb=1"
        } else {
            newSrc = newSrc + "?disablekb=1"
        }

        $(this).attr('src', newSrc)
    });
});

// Trap tab focus inside mobile menu while its open
// https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
function trapFocus(element, first, exclude) {
    // var focusableEls = element.find('a, object, :input, iframe, [tabindex]'),
    var focusableEls = element.find(`a:not(div${exclude} a)`),
        firstFocusableEl = first,
        lastFocusableEl = focusableEls.last()[0],
        KEYCODE_TAB = 9;

    $(element).on('keydown', function(e) {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) { 
            return; 
        }

        if ( e.shiftKey ) /* shift + tab */ {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }

    });
}