// variables.js

import variables from '../variables';
const breakpoints = variables.breakpoints;

export const screenMatcher = () => {
	const remWidth = window.innerWidth;
	// It seems that the breakpoints are in pixels so we don't need following calculations
	// Though the breakpoints are calculated with a fixed rem size of 16
	// So question is: does this work even when the root font size differs from the value we
	// expect this to be??
	// const width = window.innerWidth;
	// const remPxWidth = parseFloat(window.getComputedStyle(document.querySelector('html')).fontSize);
	// const remWidth = parseFloat(width/remPxWidth).toFixed(2);

	if (remWidth <= breakpoints.sm) return 'small';
	else if (remWidth >= breakpoints.sm && remWidth < breakpoints.md) return 'medium';
	else if (remWidth >= breakpoints.md && remWidth < breakpoints.lg) return 'large';
	else if (remWidth >= breakpoints.lg) return 'xlarge';
}

