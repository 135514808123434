import QueryString from '../lib/QueryString.js';
import InfiniteScroll from '../lib/InfiniteScroll.js';
import $ from "jquery";
import select2 from 'select2';
import Utils from "../lib/Utils";

export default class UpdateHome {
    constructor() {

        // Initialize QueryString obj default behaviour
        const qs = new QueryString();

        // ---------------------[ Infinite Scroll Module ]--------------------- //
        /**
         * Initiate and set up the configuration of infinite scroll.
         */
        let segParam = qs.get('segment');
        let segment = segParam && !isNaN(segParam) ? parseInt(segParam) : 1;
        const config = {
            segment: segment,
            container: '#updates-content',
            autoFill: true,
            autoScroll: true,
            fetchOnInitiate: true,
            ajaxRoute: '/updates/data',
            getAjaxData: qs.getAllParams,
            onSuccess: this.appendUpdates,
            updateSegmentParam: segmentNum => qs.updateParam('segment', segmentNum)
        }
        const infiniteScroll = new InfiniteScroll(config);

        // --------------------- [ Filter & Search ] --------------------- //
        const $filterForm = $('form#filter-bar');
        $filterForm.submit(e => e.preventDefault());
        $filterForm.change((e) => {
            let params = $(e.currentTarget).serialize();

            // look for the KIND that is set in the url instead of in the form
            const kind = qs.getValues('kind[]');
            const urlParams = new URLSearchParams(params);

            kind.forEach(value => {
                urlParams.append('kind[]', value);
            });

            const combinedParams = urlParams.toString();

            // qs.set(decodeURI(params)); // used to look at just the form, when there were still checkboxes there
            qs.set(decodeURI(combinedParams));

            $('#updates-content').empty();
            infiniteScroll.reset();
            infiniteScroll.fetchData();
        });

        // set up real-time search with 0.3s delay
        let typingTimeout;
        $('form#filter-bar input#search').on('input', (e) => {
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(() => $filterForm.trigger("change"), 300);
        });

        //restore filter state
        qs.getValues('kind[]').forEach(e => e && $(`form#filter-bar input[value=${e}]`).prop('checked', true));
        qs.getValues('theme[]').forEach(e => e && $(`form#filter-bar option[value=${e}]`).prop('selected', true));
        $(`form#filter-bar input#search`).val(qs.get('search'));

        // select2
        $('.js-select2').select2({
            width: 'resolve',
            minimumResultsForSearch: Infinity,
        });



        // Search bar focus
        $('.developments-search input').on('blur', function() {
            $(this).parents('.developments-search').removeClass('focus');
        });

        $('.developments-search input').on('focus', function(event) {
            $(this).parents('.developments-search').addClass('focus');
        });
    }

    /**
     * Callback for Ajax infinity scroll request
     */
    appendUpdates(res) {
        res.forEach((updateItem) => {
            // Yes, you read this correct: class="col-"
            // Basically this is needed to undo some grid styling regarding letter and word spacing
            // Also the defined stylesheet updates.scss adds col-md-3 or col-md-6 to the card!!
            $('#updates-content').append(`
                <a href="/updates/${updateItem.key}/${updateItem.id}" class="col- card-update" aria-label="Link naar '${updateItem.title}'">
                    <div class="text-overlay">
                        <small class="date">${updateItem.publicationDate}</small>
                        <h3>${updateItem.title}</h3>
                    </div>
                    <div class="image-container">
                        ${updateItem.imageHtml}
                    </div>
                </a>
            `);
        });
    }
}