import QueryString from '../lib/QueryString.js';
import InfiniteScroll from '../lib/InfiniteScroll.js';
import Utils from "../lib/Utils";

export default class PartnerIndex {
    constructor() {

        // Initialize QueryString obj default behaviour
        this.qs = new QueryString();

        // ---------------------[ Infinite Scroll Module ]--------------------- //
        /**
         * Initiate and set up the configuration of infinite scroll.
         */
        let segParam = this.qs.get('segment');
        let segment = segParam && !isNaN(segParam) ? parseInt(segParam) : 0;
        const config = {
            'segment': segment,
            'container': '#partners-content',
            'autoFill': true,
            'ajaxRoute': '/partners/data',
            'onSuccess': this.appendPartners,
            'updateParam': this.qs.updateParam
        }
        const infiniteScroll = new InfiniteScroll(config);
    }

    /**
     * Callback for Ajax infinity scroll request
     */
    appendPartners(res) {
        res.forEach((partner) => {
            $('#partners-content').append(`
               <div class="col-sm-6 col-md-4 col-lg-3">
                    <a class="card-link" href="${partner.url}" target="_blank">
                        ${partner.logo}
                    </a>
               </div>
            `)
        });
    }
}