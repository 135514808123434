// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

export default class ProjectDetail {
    constructor() {
        $(document).ready(() => {
            this.initMap();

            var swiper = new Swiper('.project-detail-swiper', {
                loop: true,
                spaceBetween: 100,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });

            $(".images-trio-link").on('click', (e) => {
                e.preventDefault();

                $(".project-detail-swiper-wrap").css("visibility", "visible");

                function trapFocus(element) {
                    // Ensure the element is focusable
                    $('.project-detail-swiper-wrap').attr('tabindex', '0');
                    $('.swiper-button-next').attr('tabindex', '0');
                    $('.swiper-button-prev').attr('tabindex', '0');
                    $('.swiper-button-close').attr('tabindex', '0');
                
                    const focusableElements = element.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
                    const firstFocusableEl = focusableElements[0];
                    const lastFocusableEl = focusableElements[focusableElements.length - 1];
                    const KEYCODE_TAB = 9;
                    const KEYCODE_ENTER = 13;
                    const KEYCODE_ESC = 27;
                    const closeButton = element.querySelector('.swiper-button-close');
                    const imagesTrio = document.querySelector('.images-trio'); 

                    element.focus();
                
                    element.addEventListener('keydown', (e) => {
                        const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
                        const isEnterPressed = (e.key === 'Enter' || e.keyCode === KEYCODE_ENTER);
                        
                        // Ensure the .images-trio element is focusable
                        if (imagesTrio) {
                            imagesTrio.setAttribute('tabindex', '-1');
                        }
                        
                        if (isEnterPressed) {
                            // Simulate click on 'swiper-button-next', 'swiper-button-prev', or 'swiper-button-close'
                            if (document.activeElement.classList.contains('swiper-button-next') || 
                                document.activeElement.classList.contains('swiper-button-prev') || 
                                document.activeElement.classList.contains('swiper-button-close')) {
                                document.activeElement.click();
                            }

                            if (document.activeElement.classList.contains('swiper-button-close') && imagesTrio) {
                                // If the close button was clicked, shift focus to .images-trio
                                imagesTrio.focus();
                            }

                        }
                
                        if (!isTabPressed) {
                            return;
                        }
                        
                        if (e.shiftKey) { // if shift key pressed for shift + tab combination
                            if (document.activeElement === firstFocusableEl) {
                                lastFocusableEl.focus(); // add focus for the last focusable element
                                e.preventDefault();
                            }
                        } else { // if tab key is pressed
                            if (document.activeElement === lastFocusableEl) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                                firstFocusableEl.focus(); // add focus for the first focusable element
                                e.preventDefault();
                            }
                        }
                    });
                };
                
                // trap focus inside popup ( the delay ensures that .focus() isn't set on a hidden div, because .focus() doesn't work on hidden divs )
                setTimeout(function() {
                    trapFocus(document.querySelector('.project-detail-swiper-wrap'));
                }, 100);

            });

            $(".project-detail-swiper-bg, .swiper-button-close").on('click', (e) => {
                $(".project-detail-swiper-wrap").css("visibility", "hidden");
            });
        });
    }

    initMap() {
        var map = L.map('map-detail', {minZoom: 7, maxZoom: 13, worldCopyJump: true});
        var markerLayer;
        var markers=[];

        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions"  target="_blank">CARTO</a>',
            maxZoom: 14
        }).addTo(map);

        var defaultIcon = L.icon({
            iconUrl: '/dist/images/marker-icon.svg',
            shadowUrl: '',
            iconSize:     [25, 41], // size of the icon
            shadowSize:   [0, 0], // size of the shadow
            //iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
            shadowAnchor: [0, 0],  // the same for the shadow
            //popupAnchor:  [1, -27] // point from which the popup should open relative to the iconAnchor
        });

        var markers = L.markerClusterGroup({showCoverageOnHover: false, chunkedLoading: true, maxClusterRadius: 50});
        var markerList = [];

        map.on('load', () => {
            if (window.marker) {
                markerList.push( L.marker(window.marker, {icon: defaultIcon}).addTo(map));
            }
            if (window.polygon) {
                markerList.push(L.polygon(window.polygon, {color: '#6E8900', opacity: 0.6, weight: 2}).addTo(map));
            }
            if (window.markersList) {
                window.markersList.forEach(marker => {
                    markerList.push( L.marker(marker, {icon: defaultIcon}).addTo(map));
                });
            }
            if (window.polygonsList) {
                window.polygonsList.forEach(polygon => {
                    markerList.push(L.polygon(polygon, {color: '#6E8900', opacity: 0.6, weight: 2}).addTo(map));
                });
            }

            if (markerList.length > 0 ) {
                markers.addLayers(markerList);
                markerLayer = map.addLayer(markers);
                map.fitBounds(markers.getBounds(), {maxZoom: 10, padding:[15,15]});
            }

        });

        map.setView([52.21158, 5.600498], 8);

    }
}