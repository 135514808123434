export default class ThemesOverview {
	constructor() {
		
        wrapped();

        $(window).resize(function() {
            wrapped();
        });

        function wrapped() {
            let offset_top_prev;
            let workGroupHeight = 0;
            let i=0;

            $('.workgroup-members .member-card').each(function(index) {
                let offset_top = $(this).offset().top;

                if (offset_top > offset_top_prev) {
                    $(this).addClass('wrapped');
                    workGroupHeight += $(this).outerHeight();
                    i++;
                } else if (offset_top == offset_top_prev) {
                    $(this).removeClass('wrapped');
                }

                offset_top_prev = offset_top;

                if ( i <= 1 ) {
                    let height = workGroupHeight + $('.workgroup-members .member-card:first-child').outerHeight();

                    $('.workgroup-members .grid-wrapper .grid').height(height);
                    $('.show-all-workgroups').css({'top': height});

                }
            });
        }
	}
}