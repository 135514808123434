/* SimpleShare buttons (c) 2013-03-04 Schuttelaar & Partners */

(function($) {
    $.fn.simpleShare = function(options) {
        var getSource = function() {
            var title = $('title').text();
            var seperator = ' - ';
            var lastIndex = title.lastIndexOf(seperator);
            if (lastIndex === false) return false;
            return title.substring(lastIndex + seperator.length);
        }

        var getTitleWithoutSource = function() {
            var title = $('title').text();
            var seperator = ' - ';
            var lastIndex = title.lastIndexOf(seperator);
            if (lastIndex === false) return false;
            return title.substring(0, lastIndex);
        }

        var getDescr = function() {
            var m = $('meta[name=description]');
            if (m.length) return m.attr('content');
            return false;
        }

        var settings = {
            'title' : $('title').text(),
            'descr' : getDescr(),
            'url' : window.location.href,
            'source' : getSource(),
            'titleWithoutSource' : getTitleWithoutSource(),
            'linkedin' : false,
            'twitter' : false,
            'facebook' : false,
            'googleplus' : false,
            'pinterest' : false,
            'email' : false,
            'whatsapp' : false,
            'tracker' : false
        };

        if (options) {
            $.extend(settings, options);
        }

        return this.each(function() {
            var html = '';
            var title = encodeURIComponent(settings.title);
            var titleWithoutSource = encodeURIComponent(settings.titleWithoutSource);
            var url = encodeURIComponent(settings.url);
            var descr = encodeURIComponent(settings.descr);
            var source = encodeURIComponent(settings.source);
            var summaryPart = ''
            if (settings.descr) summaryPart = '&summary=' + descr;
            var berichtPart = ''
            if (settings.descr) berichtPart = '&bericht=' + descr;
            var sourcePart = '';
            if (settings.source) sourcePart = '&source=' + source;
            if (settings.linkedin) html += '<a class="simpleShareButton linkedin" href="http://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + title + summaryPart + sourcePart + '" rel="nofollow" target="_blank">Share on LinkedIn</a>';
            if (settings.twitter) {
                var via = $('meta[name="twitter:creator"]').attr('content');
                if (!via) via = $('meta[name="twitter:site"]').attr('content');
                via = (!via) ? '' : ' via ' + via;
                html += '<a class="simpleShareButton twitter" href="http://twitter.com/intent/tweet?text=' + titleWithoutSource + via + '&url=' + url + '" rel="nofollow" target="_blank">Share on Twitter</a>';
            }
            if (settings.facebook) html += '<a class="simpleShareButton facebook" href="http://www.facebook.com/sharer.php?u=' + url + '" rel="nofollow" target="_blank">Share on FaceBook</a>';
            if (settings.googleplus) html += '<a class="simpleShareButton googleplus" href="https://plus.google.com/share?url=' + url + '" rel="nofollow" target="_blank">Share on Google+</a>';
            if (settings.pinterest) {
                var media = null;

                var media = $('meta[property="og:image"]').attr('content');
                if (!media) {
                    $('img').each(function() {
                        if (!media) media = this;
                        if ($(this).width() * $(this).height() > $(media).width() * $(media).height()) media = this;
                    });
                    media = $(media).prop('src');
                }

                if (media) html += '<a class="simpleShareButton pinterest" href="http://pinterest.com/pin/create/button/?url=' + url + '&media=' + media + '&description=' + titleWithoutSource + '" rel="nofollow" target="_blank">Share on Pinterest</a>';
            }
            if (settings.email) html += '<a class="simpleShareButton email" href="mailto:?subject=' + title + '&body=Link%3A%20' + url + '" rel="nofollow" target="_blank">Share by email</a>';
            if (settings.whatsapp) html += '<a class="simpleShareButton whatsapp" href="whatsapp://send?text=' + title + '%3A%20' + url + '" rel="nofollow" target="_blank">Share by WhatsApp</a>';

            $(this).html(html);

            if (settings.tracker) {
                $('a', this).click(function() {
                    var match = this.className.match('(linkedin|twitter|facebook|googleplus|pinterest)');
                    var service = (match[0]) ? match[0] : '';
                    window.location = settings.tracker + '?service=' + service + '&source=' + encodeURIComponent(window.location) + '&href=' + encodeURIComponent(this.href);
                    return false;
                });
            }
        });
    };
})(jQuery);
